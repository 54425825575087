@import url('../../variables.css');

/* Allgemeines Styling für die Testimonials-Sektion */
.testimonials-section {
  padding: 4rem 2rem;
  background-color: var(--background-color);

}

/* Styling für den Titel der Testimonials-Sektion */
.testimonials-title {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 2rem;
  position: relative;
}

.testimonials-title::after {
  content: '';
  display: block;
  width: 80px;
  height: 4px;
  background-color: var(--secondary-color);
  margin: 1rem auto;
}

/* Anpassungen für das slick-carousel */
.slick-slider {
  width: 100%;
}

.slick-list {
  width: 100%;
  overflow: hidden;
}

.slick-track {
  display: flex;
}

/* Sicherstellen, dass die Slides korrekt dargestellt werden */
.slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100% !important; /* Wichtig, um die richtige Breite zu erzwingen */
  margin: 0; /* Kein Margin zwischen den Slides */
}

/* Styling für die Testimonial-Karten */
.testimonial-card {
  background-color: var(--card-background-color);
  padding: 1.5rem 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  flex: 1;
  margin: 10px; /* Abstand zwischen den Testimonials */
  box-sizing: border-box;
  max-width: 500px; /* Begrenzung der maximalen Breite der Testimonial-Karten */
  height: auto; /* Automatische Höhe */
}

/* Hover-Effekte für die Testimonial-Karten */
.testimonial-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

/* Styling für die Sternbewertung */
.star-rating {
  margin-bottom: 1rem;
  color: var(--primary-color);
}

.star-icon {
  color: var(--secondary-color);
  font-size: 1.5rem;
  margin-right: 0.2rem;
}

/* Styling für das Zitat im Testimonial */
.testimonial-quote {
  font-size: 1.2rem;
  line-height: 1.6;
  color: var(--text-color-primary);
  margin-bottom: 1rem;
}

/* Styling für den Autor des Testimonials */
.testimonial-author {
  font-size: 1rem;
  font-weight: bold;
  color: var(--primary-color);
}

/* Anpassung der Navigationsdots */
.slick-dots li button:before {
  color: var(--primary-color);
  font-size: 0.75rem;
}

/* Anpassung der Navigationspfeile */
.slick-prev:before, .slick-next:before {
  color: var(--primary-color);
  font-size: 1.5rem;
}

/* Responsive Anpassungen */
@media (max-width: 768px) {
  .testimonial-card {
    margin-bottom: 1rem;
  }

  .slick-slide {
    padding: 0 5px;
  }
}
