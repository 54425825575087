.groups-section {
  padding: 4rem 2rem;
  min-height: 100vh !important;
}

.groups-title {
  font-size: 2.8rem;
  color: var(--primary-color);
  margin-bottom: 1.5rem;
  position: relative;
  text-align: center;
  font-weight: bold;
}

.groups-title::after {
  content: '';
  display: block;
  width: 80px;
  height: 4px;
  background-color: var(--secondary-color);
  margin: 0.5rem auto;
}

.groups-text {
  font-size: 1.2rem;
  line-height: 1.8;
  color: var(--text-color-secondary);
  margin-bottom: 1.5rem;
  text-align: center;
}

.groups-boxes {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-top: 2rem;
}

.groups-box {
  padding: 2rem;
  background-color: var(--box-background);
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.groups-subtitle {
  font-size: 1.5rem;
  color: #000;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.groups-icon {
  font-size: 3rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.cta-button-groups {
  display: block;
  width: fit-content;
  margin: 2rem auto;
  padding: 0.75rem 2rem;
  font-size: 1.2rem;
  color: white;
  background: var(--primary-color);
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease;
  text-decoration: none;
}

.cta-button-groups:hover {
  background: var(--secondary-color);
  transform: scale(1.05);
}

.groups-carousel-image {
  width: 100%; /* Bilder auf 50% der Carousel-Breite skalieren */
  height: auto;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .groups-boxes {
    grid-template-columns: 1fr;
  }

  .groups-section {
    text-align: center;
  }

  .groups-carousel-image {
    width: 100% !important; /* Auf kleineren Bildschirmen das Bild vergrößern */
  }
}
