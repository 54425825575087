@import url('../../variables.css');



.footer-section {
  background-color: var(--primary-color);
  color: white;
  padding: 2rem 0;
  max-width: 100%;
}

.footer-section h5 {
  border-bottom: 1px solid white;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
  color: white;
}

.footer-section a {
  color: white;
  text-decoration: none;
}

.footer-section a:hover {
  text-decoration: underline;
}

.footer-section .img-fluid {
  max-width: 100%;
  height: auto;
}

.partners {
  margin-top: 2rem;
  text-align: center;
  overflow-x: hidden; /* Horizontales Scrollen bei Partner-Logos verhindern */
}

.partner-logos {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; /* Sicherstellen, dass die Logos sich an die Bildschirmbreite anpassen */
}

.partner-logos img {
  margin: 0 1rem;
  max-height: 80px;
  width: auto;
}

.legal {
  margin-top: 2rem;
  text-align: center;
}

.legal p {
  margin: 0.5rem 0;
}
