.top  {
    background-image: url('../../assets/3450100_restaurant-zum-hafen-absberg.jpg');
    background-repeat: no-repeat;

    background-size: cover;
    object-fit: fill; /* Falls notwendig */
    width: 100%;
    min-height: 100vh;
   

}
html, body {
    margin: 0;
    padding: 0;
    min-height: 100vh;


  }
  
::-webkit-scrollbar {
 
  }








/* Media Queries für spezifische Bildschirmgrößen */
@media screen and (min-width: 1024px) {
    .top {
        background-position: center;
        background-size: cover;
    }
}

@media screen and (min-width: 1920px) {
    .top {
        background-position: center;
        background-size: cover;
    }
}

@media screen and (min-width: 3000px) {
   .top {
       background-position: 98%;
       background-size: cover;
   }
}
