/* App.css */

html, body {
  margin: 0;
  padding: 0;

  

}


.hero, .herocards, .footer, .container {
  width: 100%;
  max-width: 100%;
 
}

.container 
{
  max-width: 100%;
}

#root {

  display: flex;
  flex-direction: column;
}

.top {
  flex-shrink: 0; /* Verhindert, dass die Top-Leiste schrumpft */
}

main {
  flex-grow: 1; /* Hauptinhalt wächst, um den Platz zu füllen */
}

footer {
  flex-shrink: 0; /* Footer bleibt am Ende */
}
