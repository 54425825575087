@import url('../../variables.css'); /* Import your color variables */

/* Standard Navbar Styling */
.navbar {
  background: rgba(0, 0, 0, 0.5);  

  z-index: 999;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Soft shadow */
  padding: 20px 30px; /* Increased padding for a wider navbar */
  transition: background-color 0.3s ease-in-out;
  border-radius: 0; /* No rounded corners */
  position: fixed; /* Navbar stays fixed at the top */
  top: 0;
  left: 0;
  width: 100%; /* Full width */
  min-height: 10vh;
}
.navbar-logo {
  height: 100px !important;
  border-radius: 25px;
  margin-left: 30px;
  padding: 8px;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.3), rgba(135, 206, 235, 0.5)); /* Von leicht transparentem Weiß zu stärkerem Blau-Ton */
  backdrop-filter: blur(4px); /* Glas-Effekt für einen eleganten Look */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); /* Sanfter Schatten */
}




/* Menü wird auf der rechten Seite angezeigt */
.navbar-collapse {
  position: fixed;
  top: 0;
  right: 0;
  width: 250px; /* Breite des Menüs */
  height: 100%; /* Volle Höhe */
  background-color: rgba(0, 0, 0, 0.8); /* Dunkler Hintergrund */
  z-index: 998; /* Unterhalb der Navbar */
  transform: translateX(100%); /* Start außerhalb des Bildschirms */
  transition: transform 0.3s ease-in-out;
}

/* Wenn das Menü geöffnet ist */
.navbar-collapse.show {
  transform: translateX(0); /* Schiebt das Menü in den sichtbaren Bereich */
}

.navbar-brand img {
  height: 65px; /* Größeres Logo */
}

.navbar-toggler {
  border: none; 
  position: absolute; 
  top: 50%;
  right: 15px;
  transform: translate(-50%, -50%); 
  z-index: 1000;
}

.navbar-nav {
  display: flex;
  flex-direction: column; /* Staple die Menüeinträge vertikal */
  justify-content: flex-start; /* Justiere die Einträge nach oben */
  align-items: flex-start; /* Links ausrichten */
  padding-top: 60px; /* Abstand zur oberen Navbar */
}

.nav-item {
  text-align: left; /* Text links ausrichten */
  padding: 15px 0; /* Padding für jedes Menüelement */
  width: 100%; /* Volle Breite für das Item */
}

.navbar-nav .nav-link {
  color: var(--background-color); /* Textfarbe für Kontrast */
  transition: color 0.3s ease-in-out;
  padding-left: 20px; /* Abstand vom linken Rand */
}

.navbar-nav .nav-link:hover {
  color: var(--accent-color); /* Akzentfarbe beim Hover */
}

.nav-icon {
  margin-right: 8px; /* Abstand zwischen Icon und Text */
  font-size: 2rem; /* Größe des Icons */
}

.nav-text {
  font-size: 1rem; /* Textgröße */
}

.navbar-actions {
  display: flex;
  align-items: center;
}

/* Telefonnummer Styling */
.phone-link {
  margin-right: 130px; /* Abstand zum Hamburger-Button */
  font-size: 1.5rem; /* Anpassung der Größe des Icons */
  top: 50%;
  text-decoration: none;
  display: flex;
  align-items: center;
  color: white;
}



/* Spezielles Styling für das Telefon-Icon */
.phone-link .phone-icon {
  font-size: 1.5rem; /* Icon bleibt unverändert */
}

.phone-link .phone-number {
  margin-left: 10px; /* Abstand zwischen Icon und Telefonnummer */
color: white;
}

/* Telefonnummer auf mobilen Geräten ausblenden */
@media (max-width: 768px) {
  .phone-link {
    display: none;
  }
   

  .navbar {
    padding: 10px 20px; /* Weniger Padding auf mobilen Geräten */
  }

  .navbar-logo {
    height: 60px; /* Kleinere Logo-Größe auf mobilen Geräten */
  }

  .navbar-brand img {
    height: 50px; /* Kleinere Logo-Größe */
    margin-left: 10px; /* Weniger Rand */
  }

  .navbar-toggler {
    font-size: 1.5rem; /* Größeres Hamburger-Icon */
    padding: 10px;
  }

  .navbar-collapse {
    width: 100%; /* Das Menü nimmt die volle Breite auf mobilen Geräten ein */
  }

  .navbar-nav {
    padding-top: 20px; /* Weniger Abstand nach oben */
  }

  .nav-item {
    padding: 10px 0; /* Weniger Padding für mobile Items */
  }

  .nav-link {
    padding-left: 15px; /* Weniger Abstand vom linken Rand */
    font-size: 1.2rem; /* Etwas größere Schriftgröße für bessere Lesbarkeit */
  }

  /* Hero-Text Styling für bessere Lesbarkeit auf mobilen Geräten */
  .hero-text {
    padding: 20px;
    font-size: 1.2rem;
    text-align: center; /* Text zentrieren auf kleineren Bildschirmen */
  }

  .hero-heading {
    font-size: 2rem; /* Größere Schriftgröße */
  }

  /* Call-to-Action Button zentrieren und vergrößern */
  .cta-button {
    font-size: 1.1rem;
    padding: 12px 24px;
    margin: 20px auto;
    display: block;
  }
}
