/* LegalNotice.css */

.legal-notice-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f8f8f8;
    padding: 40px;
  }
  
  .legal-notice-box {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    width: 100%;
  }
  
  .legal-notice-title {
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .legal-section {
    margin-bottom: 30px;
  }
  
  .legal-subtitle {
    font-size: 1.5rem;
    color: #555;
    margin-bottom: 15px;
  }
  
  .legal-text {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
    margin-bottom: 10px;
  }
  
  .legal-list {
    margin-left: 20px;
    margin-bottom: 10px;
    list-style-type: disc;
  }
  
  .legal-list li {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
  }
  
  a {
    color: #1e90ff;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 768px) {
    .legal-notice-box {
      padding: 20px;
    }
  
    .legal-notice-title {
      font-size: 1.5rem;
    }
  
    .legal-subtitle {
      font-size: 1.25rem;
    }
  
    .legal-text,
    .legal-list li {
      font-size: 0.9rem;
    }
  }
  