@import url('../../variables.css');

.about-section {
  padding: 5rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  flex-wrap: wrap; 
}

.about-content {
  max-width: 100%;
  background: rgba(255, 255, 255, 0.9);
  padding: 2rem;
  border-radius: 20px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(10px);
}

.section-title {
  font-size: 2.8rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
  position: relative;
}

.section-title::after {
  content: '';
  position: absolute;
  width: 80px;
  height: 4px;
  background: var(--secondary-color);
  bottom: -10px;
  left: 0;
}

.section-subtitle {
  font-size: 1.5rem;
  color: var(--text-color-secondary);
  margin-bottom: 1.5rem;
}

.section-description1 {
  font-size: 1.125rem;
  line-height: 1.8;
  color: var(--text-color-secondary);
  text-align: left; /* Text linksbündig ausrichten */
  word-break: break-word; /* Lange Wörter umbrechen */
}

.about-image-container {
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.0);
  width: 100%; /* Sicherstellen, dass der Container die volle Breite einnimmt */
  height: auto;
}

.about-image-container img {
  object-fit: cover; /* Das Bild proportional skalieren und den Container ausfüllen */
  width: 100%; /* Das Bild soll die volle Breite des Containers einnehmen */
  height: 100%; /* Fülle den Container in der Höhe */
}

.image-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.image-container:hover::before {
  opacity: 1;
}

.about-image {
  transition: transform 0.4s ease;
  transform: scale(1);
  width: 100%; /* Sicherstellen, dass die Bilder die volle Breite einnehmen */
  height: 100%; /* Fülle den Container in der Höhe */
}

.image-container:hover .about-image {
  transform: scale(1.05);
}

.slick-dots {
  bottom: -25px;
}

.slick-dots li button:before {
  color: var(--primary-color);
}

@media (max-width: 768px) {
  .about-section {
    padding: 3rem 1rem;
    flex-direction: column; /* Inhalte untereinander anordnen */
  }

  .section-title {
    font-size: 2.2rem;
  }

  .section-subtitle {
    font-size: 1.25rem;
  }

  .section-description {
    font-size: 1rem;
  }

  .about-image {
    margin-top: 1.5rem;
  }
}
