@import url('../../variables.css');


 #foodanddrink .row {
  display: flex;
  align-items: center; /* Zentriert den Inhalt vertikal */
  justify-content: space-between; /* Verteilt den Inhalt gleichmäßig in der Zeile */
}

.food-and-drink-section-unique {
  display: flex;
  align-items: center; /* Zentriert den Inhalt vertikal */
  justify-content: center; /* Zentriert den Inhalt horizontal */
  min-height: 100vh; /* Höhe der Sektion mindestens die volle Höhe des Viewports */
}
.food-drink-card-unique {
  background: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
}

.food-drink-title-unique {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 1.5rem;
  position: relative;
}

.food-drink-title-unique svg {
  margin-right: 10px; /* Abstand zwischen Icon und Text */
}

.food-drink-description-unique {
  font-size: 1.2rem;
  line-height: 1.7;
  color: var(--text-color-secondary);
  margin-bottom: 1.5rem;
}



.food-drink-description-unique svg {
  margin-right: 10px; /* Abstand zwischen Icon und Text */
  color: var(--primary-color); /* Farbe der Icons */
}

.food-drink-carousel-unique {
  display: flex;
  justify-content: center; /* Zentriert das Karussell horizontal */
  align-items: center; /* Zentriert das Karussell vertikal, wenn nötig */
  border-radius: 10px;
  overflow: hidden;
  max-height: 40vh;
  object-fit: contain;
  margin: 0 auto; /* Sorgt dafür, dass der Container automatisch zentriert wird */
}

@media (max-width: 768px) {
  .food-drink-carousel-unique {
    height: 30vh; /* Reduziere die Höhe auf mobilen Geräten */
  }
}

.food-drink-carousel-img-unique {
  border-radius: 10px;
  transition: transform 0.4s ease, filter 0.4s ease;
  object-fit: cover; /* Stelle sicher, dass das Bild das gesamte Karussell ausfüllt */
  width: 100%;
  max-height: 400px; /* Begrenze die Höhe des Bildes */
}

.food-drink-carousel-img-unique:hover {
  transform: scale(1.05);
  filter: brightness(85%);
}

@media (max-width: 768px) {
  .food-and-drink-section-unique {
    padding: 2rem 1rem;
  }

  .food-drink-title-unique {
    font-size: 2rem;
  }

  .food-drink-description-unique {
    font-size: 1rem;
  }

  .food-drink-carousel-img-unique {
    max-height: 300px; /* Reduziere die Höhe auf kleineren Bildschirmen */
  }
}
