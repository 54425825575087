@import url('../../variables.css');
@import url("../../../fonts.css");
body {
  font-family: 'Open Sans', sans-serif;
  color: var(--text-color);
}

.hero {
  z-index: 800;
  min-height: 90vh;
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
}

.hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Dunkles Overlay */
  z-index: 1;
}

.hero::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 1) 100%);
  z-index: 2;
}

.hero-content {
  position: relative;
  z-index: 3;
  max-width: 80%;
  animation: fadeIn 1.5s ease-in-out 0.5s;
  animation-fill-mode: both;
  text-shadow: 2px 2px 14px var(--primary-color);
}

.hero h1 {
  font-size: 4.5rem;
  margin-bottom: 1rem;
  font-family: 'Montserrat', sans-serif;
  background: linear-gradient( 135deg, var(--primary-color), var(--secondary-color));
  -webkit-background-clip: text;
  color: #ffffff;
  letter-spacing: 1.7px; /* Bessere Lesbarkeit */
  animation: slideInFromLeft 1.5s ease-out;
}

.hero p {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  color: var(--text-color-light);
  line-height: 1.6; /* Bessere Lesbarkeit */
  animation: slideInFromRight 1.5s ease-out;
}

.cta-button {
  padding: 0.75rem 2rem;
  font-size: 1.2rem;
  color: white;
  background: var(--primary-color);
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
}

/* Hover-Effekte nur auf Desktops */
@media (min-width: 1024px) {
  .cta-button:hover {
    background: var(--secondary-color);
    transform: scale(1.1); 
  }

  .card:hover {
    transform: translateY(-15px); /* Stärkeres Anheben beim Hover */
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.3);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInFromLeft {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInFromRight {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  padding: 2rem 1rem;
  animation: fadeIn 1.5s ease-in-out 1s; 
  background-color: white;
}

.card {
  background: white;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 340px;
  height: 450px;
}

.card img {
  width: 100%;
  max-height: 50%;
  object-fit: cover;
  filter: brightness(90%);
}

.card-content {
  padding: 1.5rem;
  text-align: center;
}

.card h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-family: 'Montserrat', sans-serif;
  color: var(--primary-color);
}

.card p {
  font-size: 1rem;
  color: var(--text-color-secondary);
  line-height: 1.5;
}

@media (max-width: 768px) {
  .hero h1 {
    font-size: 2.5rem;
  }

  .hero p {
    font-size: 1.2rem;
  }

  .cta-button {
    font-size: 1rem;
  }

  .cards-container {
    flex-direction: column;
    gap: 1rem;
  }

  .card {
    width: 100%;
  }
}
