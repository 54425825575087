.contact-section {
  padding: 4rem 2rem;
  background-color: var(--background-color-light);
}

.contact-title,
.contact-info-title {
  font-size: 2.2rem;
  color: var(--primary-color);
  margin-bottom: 1.5rem;
  text-align: center;
  font-weight: 600;
  margin-top: 10%;
}

.contact-form,
.contact-info-container {
  background: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  align-items: center;
  font-size: 1.1rem;
  color: var(--text-color-primary);
  margin-bottom: 0.5rem;
  font-weight: normal;
}

.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  color: var(--text-color-primary);
  border: 1px solid var(--primary-color);
  border-radius: 10px;
  background: var(--background-color-light);
  transition: border-color 0.3s ease;
  box-sizing: border-box;
}

.form-group input:focus,
.form-group textarea:focus,
.form-group select:focus {
  border-color: var(--secondary-color);
  outline: none;
}

.form-group input[type="checkbox"] {
  margin-right: 10px;
  width: 18px;
  height: 18px;
  vertical-align: middle;
}

.form-group a {
  color: var(--primary-color);
  text-decoration: none;
}

.form-group a:hover {
  text-decoration: underline;
}

.cta-button-submit,
.toggle-button,
.toggle-group-size-btn {
  display: inline-block;
  padding: 0.75rem 2rem;
  font-size: 1.1rem;
  color: white;
  background: var(--primary-color);
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease;
  margin-top: 1rem;
}

.cta-button-submit:hover,
.toggle-button:hover,
.toggle-group-size-btn:hover {
  background: var(--secondary-color);
  transform: scale(1.05);
}

.contact-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  flex: 1;
}

.contact-info p {
  font-size: 1.2rem;
  color: var(--text-color-primary);
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.contact-info p svg {
  margin-right: 0.75rem;
  color: var(--primary-color);
  font-size: 1.5rem;
}

/* Tabelle für Öffnungszeiten */
.opening-hours-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
}

.opening-hours-table td {
  padding: 0.5rem 1rem;
  font-size: 1.1rem;
  color: var(--text-color-primary);
  border-bottom: 1px solid var(--background-color-light);
}

.opening-hours-table td:first-child {
  text-align: left;
  font-weight: 600;
}

.opening-hours-table td:last-child {
  text-align: right;
}

.offseason-hours td {
  color: var(--text-color-secondary);
}

/* Mobile Anpassungen */
@media (max-width: 768px) {
  .contact-section {
    padding: 2rem 1rem;
  }

  .contact-title,
  .contact-info-title {
    font-size: 1.8rem;
  }

  .contact-info p {
    font-size: 1rem;
  }

  .opening-hours-table td {
    font-size: 1rem;
    padding: 0.5rem 0.75rem;
  }

  .opening-hours-table td:last-child {
    text-align: left;
  }
}
