@import url('../../variables.css');

.water-activities-section {
  padding: 4rem 2rem;
  background: var(--background-color);
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.water-text-container {
  background: rgba(255, 255, 255, 0.9);
  padding: 2rem;
  border-radius: 20px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(10px);
  flex: 1;
  max-width: 100%;
}

.section-title {
  font-size: 3rem;
  color: var(--primary-color);
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
}

.icon-large {
  font-size: 6rem;
  margin-right: 1rem;
  color: var(--primary-color);
}
.icon-large2 {
  font-size: 3rem;
  margin-right: 1rem;
  color: var(--primary-color);
}

.section-description {
  font-size: 1.25rem;
  line-height: 1.8;
  color: var(--text-color-secondary);
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
}

.section-tip {
  font-size: 1.2rem;
  font-style: italic;
  color: var(--secondary-color);
  margin-top: 1rem;
  display: flex;
  align-items: center;
}

.section-link {
  display: inline-block;
  margin-top: 1.5rem;
  font-size: 1rem;
  color: var(--link-color);
  text-decoration: underline;
  transition: color 0.3s ease;
}

.section-link:hover {
  color: var(--link-hover-color);
}

.image-containerwater {
  position: relative;
  overflow: hidden;
  border-radius: 20px;

}

.water-activities-image {
  transition: transform 0.4s ease, filter 0.4s ease;
  transform: scale(1);
  filter: grayscale(0%);
  width: 100%;
}

.image-container:hover .water-activities-image {
  transform: scale(1.05);
  filter: grayscale(20%);
}

.slick-dots {
  bottom: -30px; /* Position der Navigationsdots */
}

.slick-dots li button:before {
  color: var(--primary-color);
}

@media (max-width: 768px) {
  .water-activities-section {
    flex-direction: column;
  }

  .water-text-container, .image-container {
    max-width: 100%;
    margin-bottom: 2rem;
  }

  .section-title {
    font-size: 2.4rem;
  }

  .icon-large {
    font-size: 8rem;
  }

  .section-description {
    font-size: 1.1rem;
  }
}
