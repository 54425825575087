.pictures-gallery-section {
  padding: 4rem 2rem;
  background: linear-gradient(135deg, var(--background-color-light) 0%, var(--background-color-dark) 100%);
  color: var(--text-color-primary);
}

.gallery-title {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 2rem;
  text-align: center;
}

.gallery-title::after {
  content: '';
  display: block;
  width: 80px;
  height: 4px;
  background-color: var(--secondary-color);
  margin: 1rem auto 0;
}

.gallery-text {
  display: block;
  align-items: center;
  font-size: 1.2rem;
  line-height: 1.8;
}

.gallery-text svg {
  flex-shrink: 0; /* Verhindert, dass das Icon den Text beeinflusst */
  margin-right: 10px;
  color: var(--primary-color);
  font-size: 2.5rem;
}

.gallery-text span {
  flex-grow: 1; /* Stellt sicher, dass der Text den restlichen Platz einnimmt */
}

.bold-text {
  font-weight: bold; /* Nur fettgedruckter Text wird hervorgehoben */
  font-size: inherit; /* Beibehalten der ursprünglichen Schriftgröße */
  color: inherit; /* Beibehalten der ursprünglichen Farbe */
  line-height: inherit; /* Beibehalten der ursprünglichen Zeilenhöhe */
}

.carousel-container {
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  position: relative;
  min-height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  max-width: 100vw;
}

.carousel-modern {
  border-radius: 15px;
}

.carousel-image {
  border-radius: 15px;
  transition: transform 0.4s ease, filter 0.4s ease;
  object-fit: cover;
  width: 100%;
  max-height: 60vh;
}

.carousel-image:hover {
  transform: scale(1.05);
  filter: brightness(0.85);
}

.carousel-description {
  margin-top: 1rem;
  font-size: 1.2rem;
  color: var(--text-color-secondary);
  text-align: center;
  max-width: 800px;
  margin: 1rem auto;
}

.text-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-modern {
  background: rgba(255, 255, 255, 0.8);
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  max-width: 800px;
  margin: 0 auto;
}

.icon-list {
  list-style: none; /* Entfernt die Standard-Bullet-Points */
  padding: 0;
}

.icon-list li {
  position: relative;
  display: flex; /* Zeigt das Icon und den Text in einer Zeile */
  align-items: flex-start;
  padding-left: 2.5rem; /* Platz für das Icon schaffen */
  margin-bottom: 1.5rem;
  font-size: 1.2rem;
  line-height: 1.8;
}

.icon-list li svg {
  position: absolute;
  left: 0; /* Positioniert das Icon links vom Text */
  top: 0.2rem; /* Leichte Anpassung, um das Icon vertikal zu zentrieren */
  font-size: 1.5rem; /* Größe des Icons */
  color: var(--primary-color); /* Farbe des Icons */
}

@media (max-width: 768px) {
  .gallery-title {
    font-size: 2rem;
  }

  .icon-list li {
    padding-left: 2rem;
  }

  .icon-list li svg {
    font-size: 1.2rem;
  }

  .carousel-container {
    min-height: 60vh;
  }

  .carousel-image {
    max-height: 50vh;
  }

  .gallery-text {
    font-size: 1rem;
  }

  .carousel-description {
    font-size: 1rem;
  }

  .text-modern {
    padding: 1.5rem;
  }
}

@media (min-width: 2560px) {
  .gallery-title {
    font-size: 3rem;
  }

  .carousel-container {
    max-width: 1400px;
  }

  .text-modern {
    max-width: 900px;
  }

  .carousel-description {
    max-width: 900px;
  }

  .carousel-image {
    max-height: 70vh;
  }
}

.bold-text {
  font-weight: bold;
}

.pictures-gallery-section {
  padding: 4rem 2rem;
  background: linear-gradient(135deg, var(--background-color-light) 0%, var(--background-color-dark) 100%);
  color: var(--text-color-primary);
}

.gallery-title {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 2rem;
  text-align: center;
}

.gallery-title::after {
  content: '';
  display: block;
  width: 80px;
  height: 4px;
  background-color: var(--secondary-color);
  margin: 1rem auto 0;
}

.icon-list {
  list-style: none; /* Entfernt die Standard-Bullet-Points */
  padding: 0;
}

.icon-list li {
  position: relative;
  display: flex; /* Zeigt das Icon und den Text in einer Zeile */
  align-items: flex-start;
  padding-left: 2.5rem; /* Platz für das Icon schaffen */
  margin-bottom: 1.5rem;
  font-size: 1.2rem;
  line-height: 1.8;
}

.icon-list li svg {
  position: absolute;
  left: 0; /* Positioniert das Icon links vom Text */
  top: 0.2rem; /* Leichte Anpassung, um das Icon vertikal zu zentrieren */
  font-size: 1.5rem; /* Größe des Icons */
  color: var(--primary-color); /* Farbe des Icons */
}
