@import url('../../variables.css');

.welcome-section {
  padding: 4rem 2rem;
  background-color: var(--background-color);
  color: var(--text-color);
}

.section-title1 {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 1.5rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.intro-text {
  font-size: 1.25rem;
  margin-bottom: 2rem;
  color: var(--text-color-secondary);
}

.feature-card-container {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
}

.feature-card {
  border: none;
  border-radius: 15px;
  background-color: #ffffff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  min-width: 100%;
  object-fit: cover;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

@media (min-width: 1024px) {
  .feature-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
  }
}

.feature-icon {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.card-title {
  font-size: 1.25rem;
  font-weight: bold;
  color: var(--primary-color);
}

.card-text {
  font-size: 1rem;
  color: var(--text-color);
  text-align: center;
}

.welcome-images-container {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-top: 2rem;
}

.welcome-images-container img {
  width: 100%;
  border-radius: 15px;
  flex: 1;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

@media (min-width: 1024px) {
  .welcome-images-container img:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  }
}

@media (min-width: 768px) {
  .feature-card, .welcome-images-container img {
    height: 100%;
  }
}
