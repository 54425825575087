@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-VariableFont_wght.ttf') format('truetype'); /* Passe den Pfad zur Montserrat-Datei an */
    font-weight: 700;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-VariableFont_wdth\,wght.ttf') format('truetype'); /* Passe den Pfad zur Open Sans-Datei an */
    font-weight: 400;
    font-style: normal;
  }

