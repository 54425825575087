/* Allgemeine Sektion für die Seite */
.jobs-section {
  padding: 4rem 2rem;
  background: linear-gradient(135deg, var(--background-color-light) 0%, var(--background-color-dark) 100%);
  color: var(--text-color-primary);
  text-align: center;
}

.jobs-section h1 {
color: var(--primary-color);
}
/* Titel der Jobangebote */
.jobs-title {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 2rem;
}

.jobs-title::after {
  content: '';
  display: block;
  width: 80px;
  height: 4px;
  background-color: var(--secondary-color);
  margin: 1rem auto 0;
}

/* Textstyling für die Einleitung */
.jobs-intro {
  font-size: 1.2rem;
  color: var(--text-color-secondary);
  margin-bottom: 3rem;
  line-height: 1.8;
}

/* Masonry Grid für die Jobangebote */
.masonry-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.masonry-item {
  break-inside: avoid;
}

/* Card-Styling für die Jobangebote */
.job-card {
  border: none;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  background-color: var(--background-color-light);
}

.job-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.job-card .card-title {
  font-size: 1.5rem;
  color: var(--primary-color);
}

.job-card .card-text {
  font-size: 1.1rem;
  color: var(--text-color-secondary);
}

.job-card button {
  background-color: var(--primary-color);
  border: none;
  color: white;
  transition: background-color 0.3s ease;
}

.job-card button:hover {
  background-color: var(--secondary-color);
}

/* Sektion für Erwartungen und Angebote */
.expectations-offers-section {
  margin-top: 3rem;
}

.box {
  background-color: #f9f9f9;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.box:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
}

.expectations-box h3, 
.offers-box h3 {
  font-size: 1.8rem;
  color: var(--primary-color);
  margin-bottom: 1.5rem;
  text-align: center;
}

.icon-list {
  list-style: none;
  padding: 0;
  margin: 0 ;
}

.icon-list li {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  padding-left: 2.5rem;

}
.icon-list li .icon {
  position: absolute;
  left: 0; /* Makes sure the icon stays on the left side */
  font-size: 2rem;
  color: var(--primary-color);
  margin-right: 1rem; /* Adds space between the icon and text */
}

.icon-list li span {
  color: var(--text-color-secondary);
  line-height: 1.6;
}

/* Styling für das Kont
aktformular */
.contact-form-section {
    text-align: left !important;

  margin-top: 4rem;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 3rem;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.contact-form-section h2 {
  font-size: 2rem;
  color: var(--primary-color);
  margin-bottom: 2rem;
}

.contact-form-section .form-group {
  margin-bottom: 1.5rem;
}

.contact-form-section .form-control {
  text-align: left !important;
  border-radius: 8px;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
}

.contact-form-section .form-control:focus {
  box-shadow: none;
  border-color: var(--primary-color);
}

.contact-form-section button {
  background-color: var(--primary-color);
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.contact-form-section button:hover {
  background-color: var(--secondary-color);
}

/* Medienabfragen für mobile Geräte */
@media (max-width: 768px) {
  .jobs-title {
    font-size: 2rem;
  }

  .job-card {
    margin-bottom: 40px;
  }
  .box {
    margin-bottom: 40px;
  }

  .contact-form-section {
    padding: 2rem;
  }
}

/* Medienabfragen für sehr große Bildschirme */
@media (min-width: 2560px) {
  .jobs-title {
    font-size: 3rem;
  }

  .job-card img {
    max-height: 400px;
  }
}
